<template>
    <div>
        <nav
            class="flex flex-col h-screen  p-4 fixed top-0 left-0 w-2/3 shadow-xl bg-white z-50 transition-transform transform  ease-in-out duration-300 overflow-scroll"
            :class="[drawerStore.isDrawerOpen ? '-translate-x-0' : '-translate-x-full']"
        >
            <div class="flex flex-col items-center justify-center p-2 mb-4"
            @click="navigateTo({name:'dashboard-profile'})"
            >
                <NuxtImg
                    :src="authManager.authUserAvatarUrl.value"
                    class="border-2 rounded-full w-20 h-20 border-a-neutral-dim mx-1"
                    width="35"
                    height="35"
                    alt=""
                />
                <span class="font-semibold text-xs">{{ authManager.authUserFullName.value }}</span>

            </div>
            <BaseButton
                @click.native="gotoHome"
                primary-color-class="a-primary"
                margin-classes="mb-4"
            >
                <Icon
                    name="mdi:home"
                    class="px-1 text-3xl"
                />
                Home
            </BaseButton>
            <BaseButton
                @click.native="gotoDashboardMain"
                primary-color-class="a-primary"
                margin-classes="mb-4"
            >
                <Icon
                    name="mdi:desktop-mac-dashboard"
                    class="px-1 text-3xl"
                />
                Dashboard
            </BaseButton>
            <div
                class="flex flex-col w-full"
                v-for="(
            menuItem, index
            ) in    menuItemsWithSubItems   "
                :key="index"
            >
                <div
                    class="flex flex-row items-center text-a-neutral-light px-4 cursor-pointer select-none"
                    @click.prevent="
                        menuItem.isAccordianOn =
                        !menuItem.isAccordianOn
                        "
                >
                    <p class="font-semibold">
                        {{ menuItem.title }}
                    </p>
                    <Icon
                        class="text-2xl ml-auto"
                        :name="menuItem.isAccordianOn
                            ? 'tabler:chevron-up'
                            : 'tabler:chevron-down'"
                    />
                </div>
                <transition name="slideDown">
                    <div
                        class="flex flex-col px-2"
                        v-show="menuItem.isAccordianOn
                            "
                    >
                        <nuxt-link
                            v-for="(
                    subMenuItem, index   
                ) in    menuItem.subMenuItems   "
                            :key="index"
                            :to="{
                                name: subMenuItem.link
                                    .routeName,
                                query:
                                    subMenuItem.link
                                        .queryParams,
                            }"
                            class="hover:bg-a-neutral-dim rounded-md"
                        >
                            <p class="flex flex-row items-center py-2 text-sm">
                                <Icon
                                    class="mx-1 text-lg w-2/12"
                                    :name="subMenuItem.icon"
                                />
                                <span class="w-10/12">{{ subMenuItem.title }}</span>
                            </p>
                        </nuxt-link>
                    </div>
                </transition>
            </div>
            <BaseButton
                primary-color-class="a-neutral-dim"
                margin-classes="mt-8"
                @click="logoutUser()"
            >
                <Icon
                    name="solar:logout-line-duotone"
                    class="px-2"
                /><span>Logout</span>
            </BaseButton>
        </nav>
        <div
            v-if="drawerStore.isDrawerOpen"
            @click="drawerStore.closeDrawer()"
            class="fixed inset-0 z-40 bg-black opacity-50"
        ></div>
    </div>
</template>
  
<script setup>
const authManager = useAuthManager();
const drawerStore = useDrawer();

const menuItemsWithSubItems = computed(()=>{
  return useNavigations().dashboard_side_menu;
});

function gotoDashboardMain() {
    navigateTo({
        name: "dashboard",
    });
}

function gotoHome() {
    navigateTo({
        name: "index",
    });
}


async function logoutUser() {
    await authManager.logout('/login')
}

</script>
  
<style scoped>a.router-link-exact-active {
    font-weight: 500;
    /* background-color: #e7ebeb; */
    @apply bg-a-secondary-lightest;
    @apply border-l-4;
    @apply border-a-secondary;
    @apply text-a-secondary;
}</style>
  